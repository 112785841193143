import {
  bannerIcon,
  fourColumns,
  logos,
  solution,
} from '../../../fragments/fragments'

import Addition from '../../../components/technical-benchmark/addition'
import Banner from '../../../components/reusables/banner'
import Centered from '../../../components/reusables/centered'
import Layout from '../../../components/layout'
import Multiplcation from '../../../components/technical-benchmark/multiplication'
import React from 'react'
import Solutions from '../../../components/reusables/solutions'
import Tabs from '../../../components/reusables/tabs'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from '../../../i18n'

const SecurityToken = ({ data }) => {
  const WrappedTabs = Tabs([<Addition />, <Multiplcation />])
  return (
    <Layout>
      <Banner data={data} shade={'grey'} />
      <Centered index="0" data={data} shade={'light'} />
      <Solutions index="0" data={data} shade={'grey'} />
    </Layout>
  )
}

export default withIntl(SecurityToken)

export const imagesQuery = graphql`
  query {
    bannerCube: file(relativePath: { eq: "hero/bannerCube@3x.png" }) {
      ...bannerIcon
    }
    fivemiles: file(relativePath: { eq: "partners/fivemiles@3x.png" }) {
      ...logos
    }
    gateway_shield: file(
      relativePath: { eq: "regular/gateway-shield@3x.png" }
    ) {
      ...solution
    }
    gateway_ofn: file(relativePath: { eq: "regular/gateway-ofn@3x.png" }) {
      ...solution
    }
    gateway_rise: file(relativePath: { eq: "regular/gateway-rise@3x.png" }) {
      childImageSharp {
        fixed(width: 116, height: 108) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
